import { Controller, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { makeTwoWayObject } from "../../helpers/helpers";
import ReactFlagsSelect from "react-flags-select";
import { lang } from "../../utilities/lang";
import { Visibility } from "../../assets/Visibility";
import { VisibilityOff } from "../../assets/VisibilityOff";
import { useState } from "react";

type SimpleInputProps<T extends FieldValues> = {
    form: UseFormReturn<T>;
    fieldName: Path<T>;
    label: string;
    required: string | boolean;
    pattern?: { value: RegExp; message: string };
    onBlur?: (event: any) => void;
    className?: string;
    disabled?: boolean;
};

type PasswordInputProps<T extends FieldValues> = SimpleInputProps<T> & {
    validate?: () => boolean | string;
    minLength?: number;
};

type PhoneInputProps<T extends FieldValues> = {
    form: UseFormReturn<T>;
    label: string;
    required: string | boolean;
    pattern?: { value: RegExp; message: string };
    onBlur?: (event: any) => void;
    className?: string;
};

type SelectInputProps<T extends FieldValues> = {
    form: UseFormReturn<T>;
    fieldName: Path<T>;
    label: string;
    required: string | boolean;
    options: Array<{ key: string; value: string }>;
    className?: string;
    multiple?: boolean;
};

export const TextInput = <T extends FieldValues>(props: SimpleInputProps<T>) => {
    const error = props.form.formState.errors[props.fieldName];
    return (
        <label
            aria-label={props.label}
            className={`${props.fieldName} ${props.className} ${props.disabled ? "disabled" : ""}`}
        >
            <input
                className={`${error ? "input-error" : ""}`}
                type={"text"}
                disabled={props.disabled}
                {...props.form.register(props.fieldName, {
                    required: props.required,
                    pattern: props.pattern,
                    onBlur: props.onBlur,
                })}
            />
            {error && <span className={"error"}>{error.message?.toString()}</span>}
        </label>
    );
};

export const NumberInput = <T extends FieldValues>(props: SimpleInputProps<T>) => {
    const error = props.form.formState.errors[props.fieldName];
    return (
        <label
            aria-label={props.label}
            className={`${props.fieldName} ${props.className} ${props.disabled ? "disabled" : ""}`}
        >
            <input
                className={`${error ? "input-error" : ""}`}
                type={"number"}
                disabled={props.disabled}
                {...props.form.register(props.fieldName, {
                    required: props.required,
                    valueAsNumber: true,
                    onBlur: props.onBlur,
                })}
            />
            {error && <span className={"error"}>{error.message?.toString()}</span>}
        </label>
    );
};

export const SelectInput = <T extends FieldValues>(props: SelectInputProps<T>) => {
    const error = props.form.formState.errors[props.fieldName];
    return (
        <label aria-label={props.label} className={props.className}>
            <select
                className={`${error ? "input-error" : ""}`}
                {...props.form.register(props.fieldName, {
                    required: props.required,
                })}
                multiple={props.multiple ?? false}
            >
                {!props.multiple ? <option value={""}></option> : null}
                {props.options.map((option) => {
                    return (
                        <option key={option.key} value={option.value}>
                            {option.value}
                        </option>
                    );
                })}
            </select>
            {error && <span className={"error"}>{error.message?.toString()}</span>}
        </label>
    );
};

export const PhoneInput = <T extends FieldValues>(props: PhoneInputProps<T>) => {
    const numberCodeMapper = makeTwoWayObject({ AT: "43", CH: "41", DE: "49", FR: "33", GB: "44", IT: "39" });
    const exchangeValue = (key: string | number) => {
        return numberCodeMapper[key];
    };

    const tel1 = "tel1" as Path<T>;
    const tel2 = "tel2" as Path<T>;

    return (
        <label className={"phone-input"} aria-label={props.label}>
            <Controller
                name={tel1}
                control={props.form.control}
                render={({ field }) => (
                    <ReactFlagsSelect
                        className={"number-select"}
                        countries={["AT", "CH", "DE", "IT", "GB", "FR"]}
                        selected={field.value ? exchangeValue(field.value) : "AT"}
                        customLabels={{ AT: "+43", CH: "+41", DE: "+49", FR: "+33", GB: "+44", IT: "+39" }}
                        onSelect={async (code) => {
                            field.onChange(exchangeValue(code));
                            await props.form.trigger(tel1);
                        }}
                        rfsKey={tel1}
                    />
                )}
            />
            <input
                className={`tel-number ${props.form.formState.errors[tel2] ? "input-error" : ""}`}
                type="text"
                placeholder={"123456"}
                autoComplete={"tel2"}
                {...props.form.register(tel2, {
                    required: props.required ? `${props.label} ${lang.get("erforderlich")}` : false,
                    onBlur: () => {
                        props.form.trigger(tel2);
                    },
                    pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: "Telefonnummer darf nur Zahlen enthalten",
                    },
                })}
            />
            {props.form.formState.errors[tel2] && (
                <span className={"error"}>{props.form.formState.errors[tel2].message as string}</span>
            )}
        </label>
    );
};

export const PasswordInput = <T extends FieldValues>(props: PasswordInputProps<T>) => {
    const error = props.form.formState.errors[props.fieldName];
    const [inputType, setInputType] = useState<"password" | "text">("password");

    const p = "password" as Path<T>;
    const pc = "password_confirmation" as Path<T>;

    return (
        <label
            className={`password-input ${props.fieldName} ${props.className} ${props.disabled ? "disabled" : ""}`}
            aria-label={props.label}
        >
            <div className={"password-wrapper"}>
                <input
                    className={`${props.form.formState.errors[props.fieldName] ? "input-error" : ""}`}
                    type={inputType}
                    autoComplete={"new-password"}
                    disabled={props.disabled}
                    {...props.form.register(props.fieldName, {
                        required: `${props.label} ${lang.get("erforderlich")}`,
                        minLength: props.minLength
                            ? {
                                  value: props.minLength,
                                  message: `${lang.get("Mindestlänge: ")}${props.minLength}`,
                              }
                            : undefined,
                        validate: props.validate,
                        onBlur: () => {
                            props.form.trigger([p, pc]);
                        },
                    })}
                />
                <button
                    type={"button"}
                    tabIndex={-1}
                    className={"password-visibility"}
                    onClick={() => {
                        if (inputType === "password") {
                            setInputType("text");
                        } else {
                            setInputType("password");
                        }
                    }}
                >
                    {inputType === "password" ? <Visibility /> : <VisibilityOff />}
                </button>
            </div>
            {error && <span className={"error"}>{error.message?.toString()}</span>}
        </label>
    );
};
